import React from "react"
import Box from "./box"
import Container from "./container"
import SquareButton from "./square-button"
import Modal from "./modal"
import RoomNavigation from "./room-navigation"

const RoomNavigationButton = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <Box
        position="fixed"
        zIndex="40"
        left={[0, 0, "50%"]}
        right={[0, 0, "auto"]}
        bottom={0}
        display="flex"
        alignX="center"
        alignY="center"
        px={1}
        pb={6}
        sx={{
          transform: [null, null, "translateX(-50%)"],
        }}
      >
        <SquareButton onClick={openModal} icon="grid" aria-label="Alle Räume">
          <Box display={["none", "inline-flex"]}>Alle Räume</Box>
        </SquareButton>
      </Box>
      <Modal
        isOpen={modalIsOpen}
        onClose={closeModal}
        contentLabel="room_navigation"
      >
        <Container maxWidth="5xl">
          <RoomNavigation />
        </Container>
      </Modal>
    </>
  )
}

export default RoomNavigationButton
