import React from "react"
import Box from "./box"

const VisuallyHidden = ({ children }) => {
  return (
    <Box
      sx={{
        border: 0,
        clip: "rect(0 0 0 0)",
        height: "auto",
        margin: 0,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        width: "1px",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </Box>
  )
}

export default VisuallyHidden
