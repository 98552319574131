/** @jsx jsx */

import React from "react" // eslint-disable-line no-unused-vars
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Stack from "./stack"
import Text from "./text"
import Box from "./box"
import { Link } from "gatsby"
import Columns from "./columns"
import Column from "./column"
import Heading from "./heading"
import Image from "./image"

const RoomNavigationItem = ({ room, reverse, headingElement }) => {
  return (
    <Link
      to={`/dauerausstellung${room.childMdx.fields.slug}`}
      activeClassName="active"
      sx={{
        display: "block",
        width: "full",
        height: "full",
        "&.active": {
          cursor: "default",
        },
      }}
      aria-label={`Raum ${room.childMdx.frontmatter.order}: ${room.childMdx.frontmatter.title}`}
      key={`room_${room.childMdx.frontmatter.title}`}
    >
      <Box
        bg="muted"
        position="relative"
        display="flex"
        flexDirection="column"
        alignY="center"
        height="full"
      >
        <Columns space={0} reverse={reverse} alignY="center" flex="auto">
          <Column width="30%">
            <Image
              alt={`Titelbild für den Raum "${room.childMdx.frontmatter.title}"`}
              image={
                room.childMdx.frontmatter.cover.childImageSharp.gatsbyImageData
              }
            />
          </Column>
          <Column>
            <Box p={6}>
              <Stack space={1}>
                <Text size={1} sans bold color="whisper">
                  {`Raum ${room.childMdx.frontmatter.order}`}
                </Text>
                <Heading
                  as={headingElement}
                  level={5}
                  sx={{
                    ".active &": {
                      color: "primary",
                    },
                  }}
                >
                  {room.childMdx.frontmatter.title}
                </Heading>
              </Stack>
            </Box>
          </Column>
        </Columns>
      </Box>
    </Link>
  )
}

RoomNavigationItem.propTypes = {
  room: PropTypes.object,
  headingElement: PropTypes.string,
  reverse: PropTypes.bool,
}

RoomNavigationItem.defaultProps = {
  headingElement: "h5",
  reverse: false,
}

export default RoomNavigationItem
